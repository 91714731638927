import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export const langList = [
  { path: "zh-TW", name: "中文" },
  { path: "en", name: "English" },
  { path: "ko", name: "한국어" },
  { path: "vi", name: "Tiếng Việt" },
  { path: "ja", name: "日本語" },
];

function Header() {
  const [isShowAboutDrop, setIsShowAboutDrop] = useState(false);
  const [isLangMenuOpen, setIsLangMenuOpen] = useState(false);
  const [path, setPath] = useState("");
  const { i18n } = useTranslation();
  const location = useLocation();
  var isAboutMenu = false;
  var isAboutSubMenu = false;
  var isLangMenu = false;
  var isLangSubMenu = false;

  useEffect(() => {
    const path = location.pathname.split("/");
    if (langList.some((lang) => lang.path === path[1])) {
      if (path.length === 3) {
        setPath(`/${path[2]}`);
      } else {
        setPath("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMouseEnter = ({ forAbout }) => {
    if (forAbout) {
      isAboutMenu = true;
      setIsShowAboutDrop(true);
    } else {
      isLangMenu = true;
      setIsLangMenuOpen(true);
    }
  };

  const handleMouseLeave = ({ forAbout }) => {
    if (forAbout) {
      isAboutMenu = false;
    } else {
      isLangMenu = false;
    }

    setTimeout(() => handleCloseDropDown(forAbout), 100);
  };

  const handleDropDownMouseEnter = ({ forAbout }) => {
    if (forAbout) {
      isAboutSubMenu = true;
    } else {
      isLangSubMenu = true;
    }
  };

  const handleDropDownMouseLeave = ({ forAbout }) => {
    if (forAbout) {
      isAboutSubMenu = false;
    } else {
      isLangSubMenu = false;
    }
    setTimeout(() => handleCloseDropDown(forAbout), 100);
  };

  const handleCloseDropDown = (forAbout) => {
    if (forAbout) {
      if (!isAboutMenu && !isAboutSubMenu) {
        setIsShowAboutDrop(false);
      }
    } else {
      if (!isLangMenu && !isLangSubMenu) {
        setIsLangMenuOpen(false);
      }
    }
  };

  return (
    <header id="main-navbar" className="fixed-top">
      <div className="line line-top"></div>
      <nav className="navbar navbar-expand-lg navbar-light bg-white p-0">
        <div className="container">
          <a className="navbar-brand" href={`/${i18n.language}`}>
            <img className="logo" alt="logo" src="/images/logo.svg" />
          </a>
          <button
            className="navbar-toggler border-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li
                className="nav-item"
                onMouseOver={() => handleMouseEnter({ forAbout: true })}
                onMouseOut={() => handleMouseLeave({ forAbout: true })}
              >
                <div className="nav-link px-3">
                  <span className="link-name">About</span>
                </div>
                {isShowAboutDrop && (
                  <div
                    className="lang-dropdown"
                    onMouseOver={() => handleMouseEnter({ forAbout: true })}
                    onMouseOut={() => handleMouseLeave({ forAbout: true })}
                  >
                    <ul>
                      <li>
                        <a
                          href={`/${i18n.language}/aboutUs`}
                          className="lang-option"
                        >
                          HyperG
                        </a>
                      </li>
                      <li>
                        <a
                          href={`/${i18n.language}/aboutATG`}
                          className="lang-option"
                        >
                          AppTotalGo
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </li>
              <li className="nav-item">
                <a
                  className="nav-link px-3"
                  href={`/${i18n.language}/contact`}
                  target="_self"
                >
                  <span className="link-name">Contact</span>
                </a>
              </li>

              <li
                className="nav-item"
                onMouseOver={() => handleMouseEnter({ forAbout: false })}
                onMouseOut={() => handleMouseLeave({ forAbout: false })}
              >
                <div className="nav-link px-3">
                  <span className="link-name ">Language</span>
                </div>
                {isLangMenuOpen && (
                  <div
                    className="lang-dropdown"
                    onMouseOver={() =>
                      handleDropDownMouseEnter({ forAbout: false })
                    }
                    onMouseOut={() =>
                      handleDropDownMouseLeave({ forAbout: false })
                    }
                  >
                    <ul>
                      {langList.map((lang) => (
                        <li key={lang.path}>
                          <a
                            href={`/${lang.path}${path}`}
                            className="lang-option"
                          >
                            {lang.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="line line-bottom"></div>
    </header>
  );
}
export default Header;
