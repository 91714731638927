import en from "./en-US.json";
import zhTW from "./zh-TW.json";
import ko from "./en-KO.json";
import vi from "./vi-VN.json";
import ja from "./ja-JA.json";

const resources = {
  "en": {
    translation: en,
  },
  "zh-TW": {
    translation: zhTW,
  },
  "ko": {
    translation: ko,
  },
  "vi": {
    translation: vi,
  },
  "ja": {
    translation: ja,
  },
};

export default resources;